@mixin for-phone-up {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin for-tablet-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-laptop-up {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@font-face {
  font-family: 'Segoe UI Regular';
  font-style: normal;
  font-weight: normal;
  src: url('../public/fonts/Segoe\ UI.woff') format('woff');
}

@font-face {
  font-family: 'RICHENCO';
  src: url(../public/fonts/RICHIESCHICHUE-BF64dc845467ba8.ttf);
}

@include for-phone-up {
  .mobileAndDown {
    display: none !important;
  }
}

@include for-tablet-up {
  .tabletAndDown {
    display: none !important;
  }
}

@include for-laptop-up {
  .laptopAndDown {
    display: none !important;
  }
}

html{
  scroll-behavior: smooth;
}

main{
  padding-top: 88px;
  width: 100%;
}

.no-scroll{
  overflow: hidden;
}

.bigRadioButton{
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 40px;
  width: 42px;

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input:checked ~ .checkmark {
    background-color: #ccc;
  }
  

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #787575;
    border-radius: 4px;
    font-size: 1rem;
  }
}

.formInput{
  width: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-bottom: 3px;

  &.fullWidth{
    width: 100%;
  }

  @include for-laptop-up {
    width: calc(50% - 10px);
  }

  input:invalid[touched="true"] ~ span{
    display: block;
  }

  input:invalid[touched="true"]{
    border-color: #ba1a1a;
    
  }

  span{
    font-size: 0.65rem;
    color: #ba1a1a;
    display: none;
    position: absolute;
    bottom: 0;
    }
}

.textGreen {
  color: #337A79;
  font-weight: 600;
}

.radioBtn{
  display: flex;
  cursor: pointer;
  margin-bottom: 1rem;
  input{
    margin-bottom: 0;
    margin-right: 5px;
    width: 15px !important;
  }
}

button {
  width: min(320px, 100%);
  height: 56px;
  color: white;
  background-color: #337A79;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;

  &.btnTransparent{
    background-color: transparent;
    border:  1.5px solid #337A79;
    color: #1F1D1D;
  }

  @include for-laptop-up {
    width: 380px;
  }
}

h2 {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 1.8rem;

  @include for-laptop-up {
    font-size: 2.2rem;
    margin-bottom: 2.2rem;
  }
}

.displayFlex {
  display: flex;
}

input {
  border: none;
  outline: none;
  border-bottom: 1.5px solid #D9D9D9;
  color: #787575;
  padding: 8px 0;
  margin-bottom: 10px;
}

textarea{
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 1.5px solid #D9D9D9;
  color: #787575;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1F1D1D;
}

ul li {
  list-style: none;

  &.listHead {
    font-weight: 600;
  }
}

a {
  text-decoration: none;
  color: #1F1D1D;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI Regular';
}


.font2{
  font-family: 'RICHENCO';
  font-weight: 100;
  color: #337A79;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  /* background-color: red; */
  width: min(1200px, 100% - 2 * 1rem);
  margin-inline: auto;
}

.container--v2 {
  max-width: 1200px;

  @include for-laptop-up {
    max-width: unset;
    width: min(1200px, 100% - 2 * 1rem);
    margin-inline: auto;
  }
}



#welcome {
  height: calc(100vh - 88px);
  position: relative;
  margin-bottom: 7rem;



  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .welcome--overaly {
    background: linear-gradient(92.68deg, #333333 -108.1%, rgba(32, 32, 32, 0) 98.99%);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 15px;

    .welcome--wrap {
      justify-content: center;
      height: 100%;
      display: flex;
      align-items: center;

      @include for-laptop-up {
        transform: none;
        justify-content: unset;
      }

      .welcome--text {

        color: white;
        width: 320px;

        h1 {
          font-size: 2.25rem;
          margin-bottom: 1rem;

          @include for-laptop-up {
            font-size: 3rem;
          }
        }

        p {
          line-height: 24px;
          margin-bottom: 2rem;
        }
      }
    }

  }
}






//   catalogue


#catalogue {
  // min-height: 100vh;
  max-width: 1000px;
  margin-bottom: 7rem;

  .catalogue--grid {
    display: grid;
    grid-template-columns: min(50%, 240px) min(50%, 240px);
    justify-content: center;
    gap: 0.5rem;
    row-gap: 1rem;

    @include for-laptop-up {
      gap: 1rem;
      row-gap: 2rem;
      grid-template-columns: repeat(5, 1fr);
    }

    &--cell {

      .catalogue--image--container{
        width: 100%;
        aspect-ratio: 180 / 252;
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          transform:  scale(1.2);
        }
      }

      p {
        font-size: 1rem;
        margin-top: 0.4rem;
        text-align: center;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }
    }
  }
}


#laButique {
  margin-inline: auto;
  // padding: 120px 0px;
  margin-bottom: 3rem;


  .laButique--background {
    position: relative;
    max-width: 490px;
    margin: 0 auto;
    margin-bottom: 1rem;

    @include for-tablet-up {
      max-width: unset;
      margin-bottom: 2.5rem;
    }

    div{
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .laButique-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      padding: 1rem;

      h2{
        font-family: 'RICHENCO';
      }



      h2,
      p {
        color: white;
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 2rem;
      }

    }
  }

  .laButique--pros {
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 1rem;

    @include for-laptop-up {
      flex-direction: row;
      justify-content: space-around;
    }

    .laButique--pros-cell {
      max-width: none;

      @include for-laptop-up{
        max-width: 240px;
      }

    }
  }
}


#locationMap {
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
    z-index: 1;
    pointer-events: none;
  }

  iframe {
    width: 100%;
    height: 540px;
    border: none;
  }
}


// footer

footer {
  background-color: #337A79;
  
  .footer-wrap {
    padding: 4rem 0rem;
    display: flex;
    flex-direction: column;

    @include for-tablet-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .footerImage {
      width: 145px;

      @include for-tablet-up {
        width: 375px;
      }
    }

    ul {
      margin-bottom: 1rem;
    }

    a,
    li {
      color: white;
      // line-height: 1.5rem;
    }

    li {
      display: flex;
      align-items: center;
      line-height: 1.5rem;

      img {
        padding-right: 0.8rem;
      }
    }

    a {
      line-height: 2.5rem;
    }
  }
}






#shop {
  padding-top: 1.5rem;
  padding-bottom: 4rem;

  @include for-laptop-up {
    padding-top: 3rem;
    padding-bottom: 8rem;
  }

  .shopNav {
    text-align: center;
    margin-bottom: 4px;
  }

  .shopTools {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .shop--filter {
      cursor: pointer;
      display: flex;
      justify-content: center;
      gap: 0.8rem;
    }
  }

  .shop--grid {
    display: grid;
    // grid-template-columns: minmax(auto, 240px) minmax(auto, 240px);
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    column-gap: 0.5rem;
    row-gap: 1rem;
    margin-bottom: 1.5rem;


    @include for-tablet-up {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1rem;
      row-gap: 2.5rem;
    }

    // .shop--grid--cell{
    //   img{

    //     width: 100%;
    //     margin-bottom: 0.5rem;
    //   }

    //   .itemName{
    //     background-color: red;
    //     font-weight: 600;
    //     font-size: 1.25rem;
    //   }
    //   .itemPrice{
    //     color: #337A79;
    //   }
    // }
  }

  .shopButton {
    display: flex;
    justify-content: center;
  }

}









#shopItem {
  margin-bottom: 3rem;

  .item--wrap {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    @include for-tablet-up {
      flex-direction: row;
      justify-content: space-between;
      column-gap: 30px;
    }

    @include for-laptop-up{
      column-gap: 60px;
    }

    @include for-desktop-up {
      column-gap: 120px;
    }
  }

  .item--images {
    flex: 1 0 0;

    .item--image--box {
      width: 100%;
      height: 0;
      padding-top: min(calc(90vh), calc(140% + 8px + 112px));
      position: relative;

      @include for-tablet-up {
        padding-top: min(calc(100vh - 88px - 2rem),  calc(140% - 16px - 112px));
      }

      .item--image--main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 112px - 8px);

        @include for-tablet-up {
          width: calc(100% - 16px - 80px);
          height: 100%;
          top: 0;
          right: 0;
          left: auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .item--image--carousel {
        position: absolute;
        width: 100%;
        height: 112px;
        bottom: 0;

        @include for-tablet-up {
          width: 80px;
          height: 100%;
          left: 0;
        }

        .scrollable{
          display: flex;
          gap: 1rem;
          flex-direction: row;
          height: 112px;
          overflow: auto;
          -ms-overflow-style: none;  /* Internet Explorer 10+ */
          scrollbar-width: none;  /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }
        
          @include for-tablet-up{
            flex-direction: column;
            height: 100%;
          }

          img {
            height: 100%;
            width: 80px;
            object-fit: cover;
            cursor: pointer;
            @include for-tablet-up{
              height: 112px;
            }
          }
        }


        .icon{
          width: 24px;
          height: 24px;
          position: absolute;
          // left: 50%;
          // transform: translateX(-50%);
          background-color: white;
          cursor: pointer;

          top: 50%;
          transform: rotate(-90deg) translateX(50%);

          @include for-tablet-up{
            top: auto;
            left: 50%;
            transform: translateX(-50%);
          }

          &.disabled{
            display: none;
          }
          
          &.up{
            left: 0.3rem;
            @include for-tablet-up{
              top: 0.3rem;
              left: 50%;
            }
          }

          &.down{
            right: 0.3rem;
            @include for-tablet-up{
              bottom: 0.3rem;
            }
          }
        }
      }
    }
  }


  .item--info {
    @include for-tablet-up{
      width: 380px;
    }

    .item--name {
      text-align: start;
    }

    .item--details {
      p {
        margin-bottom: 0.5rem;

        .details--key {
          font-weight: 600;
        }

        .details--value {
          color: #3D3A3A;
        }
      }
    }

    .item--price {
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 3rem;
    }

    .item--buy {
      margin-bottom: 3rem;
      width: 100%;
    }
  }
}

#La_Plongeuse {
  margin-bottom: 4rem;
  .landingImage {
    @include for-tablet-up {
      width: 100%;
      position: relative;
      height: calc(100vh - 100px);
    }

    .landingImage--info {
      width: fit-content;
      margin: 2rem auto;

      @include for-tablet-up {
        margin: unset;
        position: absolute;
        top: 0;
        width: min(1200px, 100% - 2 * 1rem);
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
      }


      .landingImage--text {
        max-width: 480px;
        h1 {
          font-size: 2.25rem;
          font-weight: 600;
          margin-bottom: 1.5rem;

          @include for-tablet-up {
            // max-width: 480px;
            font-size: 3rem;
            font-weight: 600;
            margin-bottom: 2.5rem;
          }
        }
      }

      button {
        @include for-tablet-up {
          width: 480px;
        }
      }

    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .plongues--bases {
    padding-top: 2rem;
    .plongues--set {
      margin-bottom: 60px;

      .plongues--set--title {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        margin-bottom: 1.25rem;
      }

      .plongues--set--flex {
        display: flex;
        gap: 1.25rem;
        flex-direction: column;
        align-items: center;

        @include for-tablet-up {
          justify-content: space-between;
          flex-direction: row;
        }

        &--center {
          justify-content: center;
        }

        .plongues--set--cell {

          @include for-tablet-up {
            width: calc(100% / 3 - 1.25rem);
          }

          .plongues--set--img {
            // max-width: 380px;
            width: 100%;
            margin-bottom: 10px;
          }

          .plongues--cell-info {
            .plongues--cell-name {
              text-transform: capitalize;
              font-size: 1.25rem;
              font-weight: 600;
            }

            .plongues--cell-price {
              color: #337A79;
              font-weight: 600;
            }
          }
        }
      }
    }


    .plongues--optional {
      text-align: center;

      .optional--title {
        font-size: 1.25rem;
        display: inline-flex;
        align-items: center;
        margin-bottom: 40px;

        .title--optional {
          font-weight: 1.25rem;
          font-weight: 600;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }

        .textGreen {
          font-size: 1rem;
        }
      }

      .optional--info {
        display: none;
        text-align: left;
        gap: 40px;
        justify-content: center;

        @include for-tablet-up {
          display: flex;
        }

        .info--icon {
          font-size: 1.25rem;
          font-weight: 600;
        }

        .info--text {
          max-width: 480px;
        }
      }
    }
  }

  .workProcess--title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // max-width: 380px;

    @include for-laptop-up{
      max-width: none;
      flex-direction: row;
      // justify-content: space-between;
      margin-top: 8rem;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;

      @include for-laptop-up{
        // max-width: 17.5rem;
        display: inline-block;
      }
    }

    p{
      @include for-laptop-up{
        max-width: 17.5rem;
      }
    }
  }

  .workProcess {
    margin-top: 5rem;

    .workProcess--steps {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      @include for-laptop-up {
        flex-direction: row;
      }

      .steps--cell {
        flex: 1 1 0;

        .stepNumber {
          font-size: 3rem;
          margin-bottom: 1rem;

          @include for-laptop-up {
            margin-bottom: 2rem;
          }
        }

        .stepName {
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 4px;

          @include for-laptop-up {
            margin-bottom: 8px;
          }
        }

        .stepInfo {
          line-height: 2rem;

        }
      }
    }

    .someNewText{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3rem 0 0 0;
      p{
        width: 50%;
      }
    }
  }
}



.info-expander {
  .expander-main {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    cursor: pointer;
    padding: 1rem 0;

    @include for-tablet-up {
      font-size: 1.5rem;
    }
  }

  .expander-content {
    // max-height: 0;
    transition: grid-template-rows 0.25s ease-in;
    border-bottom: 1px solid #D9D9D9;
    
    display: grid;
    grid-template-rows: 0fr;

    &.active{
      grid-template-rows: 1fr;
    }

    .expander__expanded{
      overflow: hidden;
    }


    //amosagebia amis qvemot
    .property--title {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .item--dimensions {
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

  }
}






#ourStory {
  padding: 1.5rem 0px;

  h1 {
    text-align: center;
    font-size: 2.25rem;
    margin-bottom: 2.5rem;
  }

  .ourStory__list {
    max-width: 780px;
    margin: 0 auto;

    .ourStory__cell {
      text-align: center;
      margin-bottom: 1.8rem;
      position: relative;

      @include for-laptop-up {
        display: none;
      }

      &.active {
        display: block;
      }

      .ourStory__date {
        font-weight: 400;
        margin-bottom: 1.5rem;
      }

      .ourStory_mainImage{
        width: 100%;
        aspect-ratio: 4 / 3;
        margin-bottom: 0.8rem;
        border-radius: 15px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .left, .right{
          position: absolute;
          cursor: pointer;
          top: 50%;
          transform: translateY(-50%);
          width: 50px;
          height: 50px;
          display: none;

          @include for-laptop-up{
            display: block;
          }
        }

        .left{
          left: -60px;
          transform: rotate(90deg);
        }

        .right{
          transform: rotate(-90deg);

          right: -60px;
        }
      }

      .ourStory__text{
        display: block;
        min-height: 64px;
      }

    }
  }

  .ourStory__picker__wrapper {
    display: none;
    position: relative;

    @include for-laptop-up {
      display: block;
    }

    .ourStory__picker {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: space-between;
      z-index: 2;
      max-width: 780px;
      margin: 0 auto;

      &::after {
        position: absolute;
        content: '';
        width: calc(100%);
        border-bottom: 1.5px dashed #337A79;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        left: 0;
      }


      .picker__border{
        padding: 4px;
        border-radius: 50%;
        border: 1px solid #337A79;
        background: white;

        .picker__imageContainer{
          height: 100%;
          aspect-ratio: 1 / 1;
          cursor: pointer;
          overflow: hidden;
          border-radius: 50%;

          img { 
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }


  .word__from__founder {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    @include for-tablet-up {
      flex-direction: row;
      align-items: center;
      gap: 60px;
    }

    @include for-laptop-up {
      gap: 120px;
    }

    .text__wrap {
      width: 100%;
      @include for-tablet-up{
        width: 60%;
      }
      .title {
        @include for-tablet-up {
          text-align: left;
        }
      }

      .text__main {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        line-height: 1.5rem;

        @include for-tablet-up {
          flex-direction: row;
          flex-wrap: wrap;
        }


        .smallText {
          @include for-tablet-up {
            width: calc(50% - 0.5rem);
          }
        }
      }
    }


    .founderImage__container{
      width: 100%;
      aspect-ratio: 320 / 362;
      border-radius: 15px;
      overflow: hidden;
      margin: 2rem 0;
      @include for-tablet-up {
        width: 40%;
        aspect-ratio: 480 / 543;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 20% 80%;
        object-position: 0% 0%;
  
      }
    }
  }
}


#checkout {
  .checkout__wrap {
    display: flex;
    flex-direction: column;

    @include for-laptop-up {
      flex-direction: row;

    }

    .checkout__form__container {

      @include for-laptop-up{
        width: 60%;
      }

      .checkout__form__wrap {
        padding: 2rem 1rem;

        @include for-laptop-up {
          min-height: 100vh;
          max-width: 580px;
          margin: auto;
        }

        .stepper {
          position: relative;
          display: flex;
          justify-content: space-between;
          margin-top: 2rem;
          margin-bottom: 1.5rem;

          &::after {
            position: absolute;
            content: '';
            border-bottom: 1.5px dashed #D9D9D9;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
          }

          .stepper__step {
            cursor: pointer;
            background-color: white;

            &.active {
              .stepper__step__number {
                background-color: #020202;
              }
            }

            .stepper__step__number {
              width: 1.5rem;
              height: 1.5rem;
              display: inline-block;
              text-align: center;
              border-radius: 50%;
              border: 1px solid #D9D9D9;
              color: #D9D9D9;
            }
          }
        }

        h1 {
          margin-bottom: 1.5rem;
        }


        button {
          width: 100%;
          margin-top: 2rem;
        }
      }
    }

    .checkout__items__container {
      @include for-laptop-up {
        width: 40%;
      }

      // background-color: red;
      background-color: #F6F6F6;

      .checkout__items__wrap {
        padding: 2rem 1rem;

        @include for-laptop-up{
          padding: 6rem 2rem;
        }

        .checkout__item__cell {
          .item__details {
            display: flex;
            gap: 1rem;
            align-items: flex-end;

            @include for-laptop-up {
              align-items: stretch;
            }

            .item__details__text {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .name {
                font-size: 1.25rem;
              }

              p {
                color: #3D3A3A;
                margin-bottom: 4px;
              }

              span {
                font-weight: 600;
                color: #1F1D1D;
              }

              .price {
                margin-top: 8px;
                font-weight: 600;
                color: #1F1D1D;
              }
            }

            img {
              width: 74px;
              height: 112px;
              object-fit: cover;

              @include for-laptop-up {
                width: 180px;
                height: 252px;
              }
            }
          }

          .subtotal {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            align-items: center;
            padding: 1rem 0;
            border-top: 1px solid #D9D9D9;
            border-bottom: 1px solid #D9D9D9;
            margin: 1.2rem 0 2rem 0;

            .price {
              font-size: 1.25rem;

            }
          }
        }

        .total {
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          align-items: center;
          padding: 1rem 0;
          margin: 1.2rem 0 2rem 0;
          font-size: 1.5rem;

        }
      }
    }
  }
}

.page {


  .pageContent{
    width: 100%;

    .choosePayment{
      width: 100%;
    }


    form{
      width: 100%;
      display: flex;
      flex-direction: column;

      .radioBtn{
        width: 100%;
      }

      .deliveryPrice{
        width: max-content;
        margin-left: auto;
        font-weight: 600;
      }

      @include for-laptop-up {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
      }

    }
  }

  .pageActions{
    width: 100%;
    button{
      text-transform: uppercase;
    }
  }

}






#thankYou{
  padding-top: 2rem;
  padding-bottom: 5rem;

  @include for-laptop-up{
    padding-bottom: 10rem;
  }
  .thankYou__wrap{
    // max-width: 580px;
    button{
      width: 100%;
      margin-top: 2rem;
    }

    @include for-laptop-up{
      max-width: 580px;
      margin-inline: auto;
    }

    h1{
      text-align: center;
      margin-bottom: 2rem;
    }

    .itemsList{
      .itemsCell{
        padding: 1.25rem 0;
        display: flex;
        gap: 1.25rem;
        border-bottom: 1px solid #D9D9D9;

        @include for-laptop-up{
          border-top: 1px solid #D9D9D9;
        }

        // @include for-laptop-up{
        //   flex-
        // }
        img{
          width: 74px;
          height: 112px;
        }

        .itemCell__info{
          // display: flex;
          // flex-direction: column;

          // @include for-laptop-up{
          //   flex-direction: row;
          //   gap: 1rem;
          // }

          .mainInfo{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;    
          }
  
          .priceWrap{
            margin-top: 8px;
            // @include for-laptop-up{
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            // }
          }
  
          p{
            color: #3D3A3A;
            span{
              font-weight: 600;
              color: #1F1D1D;
            }
          }
  
          h6{
            font-size: 1.25rem;
          }

  
          .price{
            color: #1F1D1D;
            font-weight: 600;
          }
        }
      }
    }
    
    .ShopMainInfo{
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      padding-top: 2rem;

      @include for-laptop-up{
        flex-direction: row;
        justify-content: space-between;
      }
      .contactInfo{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        flex: 1 1 0;

        h6{
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 0.5rem;
        }
        p{
          font-weight: 600;
        }
      }

      .priceInfo{
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .priceCell{
          display: flex;
          justify-content: space-between;
          align-items: center;

          .price{
            font-weight: 600;
          }
          .priceTotal{
            font-size: 1.5rem;
          }
        }

        .total{
          margin-top: 1rem;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.searchOverlay{
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-width:100%;  
  z-index: 10000000;
  top: 0;
  left: 0;
  background-color: rgba(120, 117, 117, 0.5);
  overflow-y: auto;
  animation: fadeIn 0.3s forwards;


  .overlayContent{
    position: absolute;

    // &.overlayFilter{
    //   right: 0;
    //   transform: none;
    //   top: 0;
    // }
    
    &.overlayContentSearch{
      left: 50%;
      transform: translateX(-50%);
      top: 20%;
      width: 60%;
      min-width: 300px;
      @include for-tablet-up{
        min-width: 580px;
      }
  
    }

    &.overlayContentFilter{
      left: 0;
      top: 0;
      width: 100%;

      @include for-laptop-up{
        width: 680px;
        left: unset;
        right: 0;
      }
    }


    .searchFrom{
      position: relative;

      img{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .search{
        width: 100%;
        height: 56px;
        background: #FCFCFC;
        border-radius: 24px;
        border: none;
        outline: none;
        padding: 0 1rem;
        font-size: 1rem;
        color: #787575;
        margin: 0;
      }
    }
  }
}

#CartComponent{
  background-color: #FCFCFC;
  min-height: 100vh;

  @include for-laptop-up{
    min-height: auto;
  }
  .cartBody{
    padding: 2rem;

    .itemList{
      @include for-laptop-up{
        max-height: calc(100vh - 330px);
        overflow-y: auto;
      }
      .checkout__item__cell {
          position: relative;
          display: flex;
          gap: 1rem;
          align-items: flex-end;
          padding: 1rem 0;
          border-bottom: 1px solid #D9D9D9;
          
          @include for-laptop-up {
            align-items: stretch;
          }

          .item__details__text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .name {
              font-size: 1.25rem;
            }

            p {
              color: #3D3A3A;
              margin-bottom: 4px;
            }

            span {
              font-weight: 600;
              color: #1F1D1D;
            }

            .price {
              margin-top: 8px;
              font-weight: 600;
              color: #1F1D1D;
            }
          }
          .image__wrap{
            width: 74px;
            height: 112px;
            @include for-laptop-up {
              width: 180px;
              height: 252px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;

            }
          }
          .removeItem{
            position: absolute;
            cursor: pointer;
            right: 1rem;
            bottom: 1rem;
            width: 24px;
            height: 24px;
          }
        }
    }
    .total {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      align-items: center;
      padding: 1rem 0;

      .price {
        font-size: 1.25rem;

      }
    // }
  }
  }
}

.filter__header, .cart__header{
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem;
  border-bottom: 1px solid #D9D9D9;
  img{
    cursor: pointer;
  }
  h3{
    font-size: 1.5rem;
  }
}

.filterActions, .cartActions{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0rem 2rem 2rem 2rem;
  gap: 1rem;

  @include for-laptop-up{
    flex-direction: row;
  }
  button{
     width: 100%;
     text-transform: uppercase;
  }
}

#filterComponent{
  background-color: #FCFCFC;

  .filterBody{
    padding: 2rem;
    padding-top: 0rem;
  }
}











  #adminHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    height: 90px;
    border-bottom: 1px solid #D9D9D9;
    background-color: white;
    z-index: 1000;
    width: 100%;
    &>div{
      display: flex;
      justify-content: space-between;
    }

    nav ul{
      display: flex;
      gap: 2rem;
    }

    .headerInfo{
      width: 126px;
      height: 24px;
    }

  }

  #createListing{
    margin-top: 100px;
    max-width: 580px;
    padding-bottom: 10rem;

    .type__of__clothing{
      display: flex;
      gap: 5rem;
    }



    input[type=text]{
      width: 100%;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    button{
      width: 100%;
    }

    .exactDimensions__wrap{
      margin-top: 5rem;

      .exactDimension__cell{
        margin: 1rem 0;
      }

      .exactDimension__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        button{
          width: fit-content;
          width: 100px;
          height: 40px;
        }
      }
  
      .dimentions{
        display: flex;
        justify-content: space-between;
        input{
          width: 30%;
        }
      }

    }

    .imageList{
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      margin-top: 50px;
    }

    .imgContainer{
      width: 136px;
      height: 200px;
      background-color: gainsboro;
      position: relative;
      border-radius: 5px;
      overflow: hidden;

      img.mainImg{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .iconDelete{
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 5px;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        
        background-color: gainsboro;
        cursor: pointer;
      }
    }


    .imgAdder{
      width: 150px;
      height: 200px;
      background-color: gainsboro;
      border-radius: 5px;
      font-size: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      input{
        display: none;
      }
    }

  }



  #adminList{
    margin-top: 100px;
    .adminList__filter{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;

      p{
        cursor: pointer;
        &.active, &:hover{
          border-bottom: 1px solid #337A79;
        }
      }
    }

    .adminList__body{
      margin-top: 5rem;
      .list__title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        margin-bottom: 2rem;
        p{
          height: 100%;
          display: flex;
          align-items: center;
          flex: 1 1 0;
          font-weight: 600;
        }

        .addItem{
          a{
            width: 100%;
          }
        }
      }
      
      .admin__list__wrap{
        .list__cell{
          border-bottom: 1px solid #D9D9D9;
          height: 105px;
          padding: 12px 0;
          display: flex;
          
          .list__cell__section{
            display: flex;
            align-items: center;
            flex: 1 1 0;

            input{
              width: 20px;
            }

            button{
              width: 60px;
              height: 30px;
              margin: 0;
              margin-left: 5px;
            }

            img{
              height: 100%;
              width: 80px;
            }
          }

          .list__cell__actions{
            gap: 1rem;
            img{
              width: 24px;
              height: 24px;
              cursor: pointer;
            }
          }

        }
      }

      button{
        margin-top: 2rem;
        margin-bottom: 1rem;
        width: 100%;
      }
    }

  }



  #orders{
    margin-top: 100px;

    h1{
      text-align: center;
      margin-bottom: 5rem;
    }
    table{
      width: 100%;
      border-collapse: collapse; /* Ensure border-spacing works */
    .table__header{
        margin-bottom: 1rem;

        th{
          padding: 0 20px 24px 0px;
        }
      }

      td, th{
        text-align: start;
        padding: 12px 20px 12px 0px;
      }

      .orderCell{
        &.last{
          border-bottom: 1px solid #D9D9D9;
        }
        .image{
          width: 100px;
          height: 104px;
          img{
            width: 100%;
            height: 100%;
          }
        }

        .contactInfo{
          font-weight: bold;
          span{
            width: 70px;
            display: inline-block;
          }
        }

        .orderItemName{
          max-width: 180px;
        }
      }
      
    }
  }


  #adminLogin{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    form{
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }


  .adminCatalogue{
    margin-top: 100px;
  }

  .addCatalogue{
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
    width: fit-content;

    form{
      display: flex;
      flex-direction: column;

      .imageContainer{
        width: 100%;
        aspect-ratio: 180/252;
        background-color: gainsboro;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
        margin-bottom: 20px;

        img{
          width: 100%;
          height: 100%;
          position: absolute;
          transform: scale(1.2);
        }

        &::after{
          content: 'Add Image';
          font-size: 3rem;
        }

        input{
          display: none;
        }
      }
      
    }
  }

  #termsPage img{
    width: 100%;
  }


  .salePage{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
}

del{
  color: #E43A36;
}

.salePrice{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 20px;
  top: 20px;
  background-color: #E43A36;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 1;
  color: white;
}


.underMaintenance{
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}